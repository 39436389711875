import React from "react";
import type { User } from "../types";

interface AuthContext {
	authenticated: boolean;
	authenticateUser: (email: string, password: string) => void;
	logout: () => void
	user?: User;
}

export const AuthContext = React.createContext<AuthContext>({} as AuthContext);
