import type { ChangeEvent } from "react";
import React, { useState, useEffect } from "react";
import {
	Alert,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import type { UploadedImage } from "../../types";
import {
	type Appointment,
	type Comment,
	type FunctionType,
	type Location,
	type Report,
	type ShiftType,
	type User,
} from "../../types";
import { enqueueSnackbar } from "notistack";
import {
	LocationRepository,
	AppointmentRepository,
	ReportRepository,
	UserRepository,
	storage,
} from "../../data/firebase";
import { MillisToTime } from "../../util";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useAuth } from "../../hooks/useAuth";
import { where } from "firebase/firestore";
import InfoIcon from "@mui/icons-material/Info";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Popup } from "../../components";
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytes,
} from "firebase/storage";
import { Delete } from "@mui/icons-material";
import imageCompression from "browser-image-compression";

export const Reports = () => {
	const { user } = useAuth();

	const [ users, setUsers ] = useState<User[]>();
	const [ date, setDate ] = useState<DateTime | null>(DateTime.now());
	const [ type, setType ] = useState<ShiftType>("");
	const [ caretaker, setCaretaker ] = useState<string>("");
	const [ location, setLocation ] = useState<string>("");
	const [ functionType, setFunctionType ] = useState<FunctionType>("");

	const [ locationOptions, setLocationOptions ] = useState<Location[]>();
	const [ subLocations, setSubLocations ] = useState<string[] | null>();

	const [ tookShiftFrom, setTookShiftFrom ] = useState<string>("");
	const [ takenKey, setTakenKey ] = useState<boolean>(false);
	const [ takenPhone, setTakenPhone ] = useState<boolean>(false);

	// Next four are only used for creating and not viewing
	const [ fromDate, setFromDate ] = useState<DateTime | null>(null);
	const [ tillDate, setTillDate ] = useState<DateTime | null>(null);
	const [ comment, setComment ] = useState<string>("");
	const [ selectedFiles, setSelectedFiles ] = useState<File[] | null>(null);
	const [ uploadedImages, setUploadedImages ] = useState<UploadedImage[] | null>(null);
	const [ importantComment, setImportantComment ] = useState<boolean>(false);
	const [ commentLocation, setCommentLocation ] = useState<string>("");

	const [ gaveShiftTo, setGaveShiftTo ] = useState<string>("");
	const [ gaveKey, setGaveKey ] = useState<boolean>(false);
	const [ gavePhone, setGavePhone ] = useState<boolean>(false);
	const [ comments, setComments ] = useState<Comment[]>([]);
	const [ editingComment, setEditingComment ] = useState<Comment | null>(null);

	const [ hasImportantComment, setHasImportantComment ] = useState<boolean>(false);

	const [ isPreviousReport, setIsPreviousReport ] = useState<boolean>(false);
	const [ loading, setLoading ] = useState<boolean>(false);

	const [ appointments, setAppointments ] = useState<Appointment[]>([]);
	const [ open, setOpen ] = useState<boolean>(false);

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const agendaDate = date ?? DateTime.now();
		let min, max;

		switch (type) {
			case "early":
				// 0800 - 1600
				min = agendaDate.startOf("day").plus({ hours: 8 });
				max = agendaDate.startOf("day").plus({ hours: 16 });
				break;

			case "late":
				// 1600 - 0000
				min = agendaDate.startOf("day").plus({ hours: 16 });
				max = agendaDate.endOf("day");
				break;

			case "night":
				// 0000 - 0800
				min = agendaDate.startOf("day");
				max = agendaDate.startOf("day").plus({ hours: 8 });
				break;

			case "dayWeekend":
				// 0800 - 2000
				min = agendaDate.startOf("day").plus({ hours: 8 });
				max = agendaDate.startOf("day").plus({ hours: 20 });
				break;

			case "nightWeekend":
				// 2000 0800
				min = agendaDate.startOf("day").plus({ hours: 20 });
				max = agendaDate.startOf("day").plus({ hours: 32 });
				break;

			default:
				min = agendaDate.startOf("day").plus({ hours: 8 });
				max = agendaDate.startOf("day").plus({ hours: 16 });
				break;
		}

		AppointmentRepository
			.getCollectionsWithFilter([
				where("date", ">=", min.setLocale("nl-NL").toMillis()),
				where("date", "<=", max.setLocale("nl-NL").toMillis()),
			])
			.then((d) => {
				const items = d.filter(a => a.location.toLowerCase() === location.toLowerCase());
				setAppointments(items);
			})
			.catch((_) => {
				1;
			});
	}, [
		type, date, location,
	]);

	useEffect(() => {
		UserRepository.getCollections()
			.then(d => setUsers(d))
			.catch(_ => enqueueSnackbar("Kon geen gebruikers vinden", { variant: "error" }));

		LocationRepository.getCollections()
			.then(d => setLocationOptions(d))
			.catch(_ => enqueueSnackbar("Kon geen locaties vinden.", { variant: "error" }));

	}, [ ]);

	useEffect(() => {
		const subs = locationOptions?.find(l => l.name === location);

		if (!subs) return;

		setSubLocations(subs.locations);
	}, [ location, locationOptions ]);

	useEffect(() => {
		clearForm();
		if (!id) return setCaretaker(user?.username ?? "");
		ReportRepository.getDocument(id)
			.then((d) => {
				setDate(DateTime.fromMillis(d.date));
				setType(d.type);
				setCaretaker(d.caretaker);
				setLocation(d.location);
				setFunctionType(d.function ?? "");

				setTookShiftFrom(d.tookShiftFrom);
				setTakenKey(d.takenKey);
				setTakenPhone(d.takenPhone);

				setGaveShiftTo(d.gaveShiftTo);
				setGavePhone(d.gavePhone);
				setGaveKey(d.gaveKey);
				setComments(d.comments);

				setIsPreviousReport(d.status !== "concept");

			})
			.catch(_ => enqueueSnackbar("Er ging iets mis met het ophalen.", { variant: "error" }));
	}, [ id, user ]);

	const clearForm = () => {
		setDate(DateTime.now());
		setType("");
		setCaretaker("");
		setLocation("");
		setFunctionType("");
		setCommentLocation("");

		setTookShiftFrom("");
		setTakenKey(false);
		setTakenPhone(false);

		setFromDate(null);
		setTillDate(null);
		setComment("");
		setImportantComment(false);

		setGaveShiftTo("");
		setGaveKey(false);
		setGavePhone(false);
		setComments([]);
		setIsPreviousReport(false);
		setHasImportantComment(false);
	};

	const automaticTempSave = (updatedComments: Comment[], temp = true) => {
		if (!temp){
			const form = document.getElementById("reportForm") as HTMLFormElement;
			if (!form.checkValidity()) return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}
		if (!date) {
			return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}
		const data: Report = {
			id: "",
			date: date.toMillis(),
			type,
			caretaker,
			location,
			function: functionType,
			tookShiftFrom,
			takenKey,
			takenPhone,
			comments: updatedComments,
			gaveShiftTo,
			gaveKey,
			gavePhone,
			hasImportantComment,
			status: !temp ? "final" : "concept",
		};

		if (id){
			ReportRepository.setDocument(id, data)
				.then(() => {
					enqueueSnackbar("Formulier geüpdate!", { variant: "success" });
					setLoading(false);
					if (!temp) return navigate("/");
				})
				.catch(_ => enqueueSnackbar("Kon het formulier niet uploaden, neem contact op.", { variant: "error" }) );
		} else {
			ReportRepository.addDocument(data)
				.then((d) => {
					enqueueSnackbar("Formulier geupload!", { variant: "success" });
					setLoading(false);
					if (temp) return navigate(`/report/${d.id}`);
					return navigate("/");
				})
				.catch(_ => enqueueSnackbar("Kon het formulier niet uploaden, neem contact op.", { variant: "error" }) );
		}
	};

	const handleSave = (temp = false) => {
		if (!temp){
			const form = document.getElementById("reportForm") as HTMLFormElement;
			if (!form.checkValidity()) return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}
		if (!date) {
			return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}
		const data: Report = {
			id: "",
			date: date.toMillis(),
			type,
			caretaker,
			location,
			function: functionType,
			tookShiftFrom,
			takenKey,
			takenPhone,
			comments,
			gaveShiftTo,
			gaveKey,
			gavePhone,
			hasImportantComment,
			status: !temp ? "final" : "concept",
		};

		if (id){
			ReportRepository.setDocument(id, data)
				.then(() => {
					enqueueSnackbar("Formulier geüpdate!", { variant: "success" });
					setLoading(false);
					if (!temp) return navigate("/");
				})
				.catch(_ => enqueueSnackbar("Kon het formulier niet uploaden, neem contact op.", { variant: "error" }) );
		} else {
			ReportRepository.addDocument(data)
				.then((d) => {
					enqueueSnackbar("Formulier geupload!", { variant: "success" });
					setLoading(false);
					if (temp) return navigate(`/report/${d.id}`);
					return navigate("/");
				})
				.catch(_ => enqueueSnackbar("Kon het formulier niet uploaden, neem contact op.", { variant: "error" }) );
		}
	};

	const handleNewComment = async () => {
		if (!fromDate || comment.length === 0) {
			return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}

		const uploadedFiles = await handleFileUpload();

		const newComment: Comment = {
			fromDate: fromDate.toMillis(),
			tillDate: tillDate ? tillDate.toMillis() : null,
			comment,
			images: uploadedFiles,
			important: importantComment,
			location: commentLocation,
		};
		const updatedComments = [ ...comments, newComment ];
		setComments(updatedComments);
		if (importantComment){
			setHasImportantComment(true);
		}
		clearCommentField();

		setSelectedFiles(null);
		automaticTempSave(updatedComments);
	};

	const handleRemoveComment = (c: Comment) => {
		if (c.images) {
			c.images.forEach(async (image) => {
				const reference = ref(storage, `images/${image.name}`);
				await deleteObject(reference);
			});
		}
		const updatedComments = [...comments.filter(p => p !== c)];
		setComments(updatedComments);
		automaticTempSave(updatedComments);
	};

	const handleEditComment = (c: Comment) => {
		setEditingComment(c);
		setFromDate(DateTime.fromMillis(c.fromDate)),
		setTillDate(c.tillDate ? DateTime.fromMillis(c.tillDate) : null);
		setComment(c.comment);
		setUploadedImages(c.images);
		setImportantComment(c.important);
		setCommentLocation(c.location ?? "");
	};

	const handleUpdateComment = async () => {
		if (!fromDate || comment.length === 0) {
			return enqueueSnackbar("Vul alle velden in", { variant: "error" });
		}

		const currentImages = uploadedImages;

		const uploadedFiles = await handleFileUpload();

		const updatedImages = [
			...(Array.isArray(currentImages) ? currentImages : []),
			...(Array.isArray(uploadedFiles) ? uploadedFiles : []),
		];

		const newComment = {
			fromDate: fromDate.toMillis(),
			tillDate: tillDate ? tillDate.toMillis() : null,
			comment,
			images: updatedImages,
			important: importantComment,
			location: commentLocation,
		};

		const updatedComments = [
			...comments.map((c) => {
				if (c !== editingComment) return c;
				return newComment;
			}),
		];

		setComments(updatedComments);
		if (importantComment){
			setHasImportantComment(true);
		}
		clearCommentField();
		setSelectedFiles(null);
		automaticTempSave(updatedComments);
	};

	const clearCommentField = () => {
		setFromDate(null);
		setTillDate(null);
		setComment("");
		setImportantComment(false);
		setCommentLocation("");
		setEditingComment(null);
	};

	const handleRemove = async (c: Comment, image: UploadedImage) => {
		// Hier in de comment de image er ook uit filteren
		try {
			const reference = ref(storage, `images/${image.name}`);
			await deleteObject(reference);

			const updatedComments = [
				...comments.map((current) => {
					if (current === c) {
						return {
							...current,
							images: c?.images?.filter(i => i.name !== image.name) ?? null,
						};
					}
					return current;
				}),
			];

			setComments(updatedComments);

			enqueueSnackbar("Foto verwijderd", { variant: "success" });
			automaticTempSave(updatedComments);
		} catch (_){
			enqueueSnackbar("Kon de foto niet verwijderen", { variant: "error" });
		}
		// setUploadedImages(prev => prev.filter(img => img.ref !== image.ref));
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;

		if (!fileList) return;

		const fileArray = Array.from(fileList);

		setSelectedFiles(fileArray);
	};

	const handleFileUpload = async(): Promise<UploadedImage[] | null> => {
		if (!selectedFiles) return null;

		const options = {
			maxSizeMB: 0.5,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		const compressedFilesPromises = selectedFiles.map(async (file) => {
			try {
				const compressedFile = await imageCompression(file, options);
				return compressedFile;
			} catch (error) {
				enqueueSnackbar(`Er ging iets mis met compressen ${file.name}`, { variant: "error" });
				return null;
			}
		});

		const compressedFiles = await Promise.all(compressedFilesPromises);

		const validCompressedFiles = compressedFiles.filter((file): file is File => file !== null);

		const uploadPromises = validCompressedFiles.map(async (file) => {
			const uniqueName = `${DateTime.now().toMillis()}-${file.name}`;

			const storageRef = ref(storage, `images/${uniqueName}`);

			try {
				await uploadBytes(storageRef, file);
			} catch (e) {
				enqueueSnackbar(`Er ging iets mis met het uploaden ${file.name}`, { variant: "error" });
			}

			const url = await getDownloadURL(storageRef);
			return { name: uniqueName, url };
		});
		const uploadedFiles = await Promise.all(uploadPromises);

		return uploadedFiles;
	};

	return (
		<Box
			px={{
				xs: 0, sm: 0, md: "20%", lg: "25%",
			}}
			pt={5}
		>
			{
				appointments.length ? (
					<Alert
						sx={{ backgroundColor: "darkred", color:"white" }}
						severity="error"
						icon={<InfoIcon sx={{ color: "white" }} />}
						action={(
							<Button
								color="inherit"
								size="small"
								variant="outlined"
								onClick={() => setOpen(true)}
							>
								Bekijk agenda
							</Button>
						)}
					>
						Let op: er zijn geplande items
					</Alert>
				) : null
			}

			<Box
				p={3}
				sx={{
					backgroundColor: "#f4f4f4", borderRadius: 2, mt: 2,
				}}
			>
				<Box>
					<Typography variant="h5">Dienstrapportage</Typography>
				</Box>

				<form
					id="reportForm"
					onSubmit={(e) => {
						e.preventDefault();
					}}
					noValidate
				>

					<Grid container spacing={2} columnSpacing={2}>
						<Spacer />

						<Grid item xs={12} md={6}>
							<MobileDatePicker
								label="Datum"
								value={date}
								onChange={newValue => setDate(newValue)}
								slotProps={{ textField: { fullWidth: true  } }}
								disabled={isPreviousReport}
							/>

						</Grid>

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="type">Dienst type *</InputLabel>

								<Select
									required
									labelId="type"
									id="typeselect"
									value={type}
									label="Dienst type"
									onChange={e => setType(e.target.value as ShiftType)}
									fullWidth
									disabled={isPreviousReport}
								>

									<MenuItem value="early">Vroege dienst 08:00-16:00</MenuItem>

									<MenuItem value="late">Late dienst 16:00-00:00</MenuItem>

									<MenuItem value="night">Nacht dienst 00:00-08:00</MenuItem>

									<MenuItem value="dayWeekend">Dagdienst weekend 08:00-20:00</MenuItem>

									<MenuItem value="nightWeekend">Nachtdienst weekend 20:00-08:00</MenuItem>

								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="caretakerId">Naam *</InputLabel>

								<Select
									required
									labelId="caretakerId"
									id="caretakerSelect"
									value={caretaker}
									label="Naam *"
									onChange={e => setCaretaker(e.target.value)}
									fullWidth
									disabled={isPreviousReport}
								>

									{
										!!users && users.map(u => (
											<MenuItem value={u.username} key={u.id}>
												{u.username}
											</MenuItem>
										))
									}

								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="locatieLabel">Locatie *</InputLabel>

								<Select
									required
									labelId="locatieLabel"
									id="locationSelect"
									value={location}
									label="Locatie *"
									onChange={e => setLocation(e.target.value)}
									fullWidth
									disabled={isPreviousReport}
								>
									{
										!!locationOptions && locationOptions.map(l => l.name).map(l => (
											<MenuItem key={l} value={l}>
												{l}
											</MenuItem>
										))
									}

								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="functieLabel">Functie *</InputLabel>

								<Select
									required
									labelId="functieLabel"
									id="locationSelect"
									value={functionType}
									label="Functie *"
									onChange={e => setFunctionType(e.target.value as FunctionType)}
									fullWidth
									disabled={isPreviousReport}
								>

									<MenuItem value="Beveiliger">Beveiliger</MenuItem>

									<MenuItem value="Huismeester">Huismeester</MenuItem>

								</Select>
							</FormControl>
						</Grid>

						<Spacer />

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="tookShiftOf">Dienst overgenomen van *</InputLabel>

								<Select
									required
									labelId="tookShiftOf"
									id="tookShiftOfSelect"
									value={tookShiftFrom}
									label="Dienst overgenomen van *"
									onChange={e => setTookShiftFrom(e.target.value)}
									fullWidth
									disabled={isPreviousReport}
								>
									{
										!!users && users.map(u => (
											<MenuItem value={u.username} key={u.id}>
												{u.username}
											</MenuItem>
										))
									}

								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<Box
								sx={{ display: "flex", alignItems: "center" }}
							>

								<FormGroup>
									<FormControlLabel
										required
										control={(
											<Checkbox
												checked={takenKey}
												onChange={() => setTakenKey(!takenKey)}
												size="small"
												disabled={isPreviousReport}
											/>
										)}
										label="Sleutelbos in ontvangst genomen"
									/>

									<FormControlLabel
										required
										control={(
											<Checkbox
												checked={takenPhone}
												onChange={() => setTakenPhone(!takenPhone)}
												size="small"
												disabled={isPreviousReport}
											/>
										)}
										label="Telefoon in ontvangst genomen"
									/>

								</FormGroup>
							</Box>
						</Grid>

						<Spacer />

						<Grid item xs={12}>
							<Typography variant="h6">Dienstverloop</Typography>
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={2}>

								{
									[...comments].sort((a, b) => a.fromDate - b.fromDate).map((c, i) => (

										<Grid
											item
											xs={12}
											md={12}
											key={`${c.fromDate}-${i}`}
											sx={{ height: "100%" }}
										>
											<Card sx={{ width: "100%", mb: 2 }} variant="elevation" elevation={4}>
												<CardHeader
													title={`${MillisToTime(c.fromDate)} ${c.tillDate ? `- ${MillisToTime(c.tillDate)}` : ""}`}
												/>

												<CardContent>
													<Typography>
														{c.location ? `${c.location} - ${c.comment}` : c.comment}
													</Typography>

													<Grid container spacing={2} mt={2}>
														{c.images?.map(image => (
															<Grid item key={image.url}>
																<Box position="relative">
																	<a href={image.url} target="_blank" rel="noreferrer">
																		<img
																			src={image.url}
																			alt={image.name}
																			width={100}
																			height={100}
																			style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
																		/>
																	</a>

																	{!isPreviousReport ? (
																		<IconButton
																			size="small"
																			style={{
																				position: "absolute",
																				top: 0,
																				right: 0,
																				color: "red",
																			}}
																			onClick={() => handleRemove(c, image)}
																		>
																			<Delete />
																		</IconButton>
																	): null}

																</Box>
															</Grid>
														))}
													</Grid>
												</CardContent>

												<CardActions>
													{
														!isPreviousReport ? (
															<Box sx={{
																display: "flex", justifyContent: "space-between", width: "100%",
															}}
															>
																<IconButton color="warning" onClick={() => handleEditComment(c)}>
																	<EditIcon />
																</IconButton>

																<IconButton color="error" onClick={() => handleRemoveComment(c)}>
																	<RemoveCircleIcon />
																</IconButton>
															</Box>
														): null
													}
												</CardActions>
											</Card>
										</Grid>
									))
								}
							</Grid>
						</Grid>

						{
							!isPreviousReport ? (
								<>
									<Grid item xs={12} md={6}>
										<MobileTimePicker
											label="Tijd*"
											minutesStep={5}
											value={fromDate}
											onChange={newValue => setFromDate(newValue)}
											slotProps={{ textField: { fullWidth: true  } }}
										/>
									</Grid>

									<Grid item xs={12} md={6}>
										<MobileTimePicker
											label="Tot"
											value={tillDate}
											onChange={newValue => setTillDate(newValue)}
											slotProps={{
												textField: { fullWidth: true  }, actionBar: {
													actions: [
														"clear", "cancel", "accept",
													],
												},
											}}
										/>
									</Grid>

									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel id="subLocationSelectLabel">Locatie</InputLabel>

											<Select
												labelId="subLocationSelectLabel"
												id="subLocationSelect"
												value={commentLocation}
												label="Locatie"
												onChange={e => setCommentLocation(e.target.value)}
												fullWidth
											>

												{
													!!subLocations && subLocations.map(s => (
														<MenuItem key={s} value={s}>
															{s}
														</MenuItem>
													))
												}

											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12}>

										<TextField
											id="specialRemarks"
											label="Bijzonderheden"
											variant="outlined"
											fullWidth
											multiline
											minRows={1}
											value={comment}
											onChange={e => setComment(e.target.value)}
										/>
									</Grid>

									<Grid item xs={12}>
										<Box sx={{ display: "flex" }}>
											<input
												accept="image/*"
												style={{ display: "none" }}
												id="contained-button-file"
												type="file"
												multiple
												onChange={handleFileChange}
											/>

											<label htmlFor="contained-button-file">
												<Button
													startIcon={
														<AddPhotoAlternateIcon />
													}
													variant="contained"
													color="primary"
													component="span"
												>
													Foto(s) toevoegen
												</Button>
											</label>
										</Box>

										<Box
											sx={{ maxWidth: "100%" }}
										>
											{!!selectedFiles && selectedFiles.map(file => (
												<Typography sx={{ width: "100%" }} key={file.name}>
													{file.name}
												</Typography>
											))}
										</Box>
									</Grid>

									<Grid item xs={12} md={6}>
										<FormGroup>
											<FormControlLabel
												control={(
													<Checkbox
														onChange={() => setImportantComment(!importantComment)}
														checked={importantComment}
													/>
												)}
												label="Mutatie opnemen in weekrapportage"
											/>
										</FormGroup>
									</Grid>

									<Grid
										item
										xs={12}
										md={12}
										sx={{ display: "flex", justifyContent: "center" }}
									>
										{
											!editingComment ? (
												<IconButton color="success" onClick={handleNewComment}>
													<AddIcon sx={{ height: 40, width: 40 }} />
												</IconButton>
											) : (
												<IconButton color="success" onClick={handleUpdateComment}>
													<SaveIcon sx={{ height: 40, width: 40 }} />
												</IconButton>
											)
										}
									</Grid>
								</>
							) : null
						}

						<Spacer />

						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel id="gaveShiftTo">Dienst overgedragen aan *</InputLabel>

								<Select
									required
									labelId="gaveShiftTo"
									id="gaveShiftToSelect"
									value={gaveShiftTo}
									label="Dienst overgedragen aan *"
									onChange={e => setGaveShiftTo(e.target.value)}
									fullWidth
									disabled={isPreviousReport}
								>

									{
										!!users && users.map(u => (
											<MenuItem value={u.username} key={u.id}>
												{u.username}
											</MenuItem>
										))
									}

								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<Box
								sx={{ display: "flex", alignItems: "center" }}
							>

								<FormGroup>
									<FormControlLabel
										required
										control={(
											<Checkbox
												checked={gaveKey}
												onChange={() => setGaveKey(!gaveKey)}
												size="small"
												disabled={isPreviousReport}
											/>
										)}
										label="Sleutelbos overgedragen"
									/>

									<FormControlLabel
										required
										control={(
											<Checkbox
												checked={gavePhone}
												onChange={_ => setGavePhone(!gavePhone)}
												size="small"
												disabled={isPreviousReport}
											/>
										)}
										label="Telefoon overgedragen"
									/>

								</FormGroup>
							</Box>
						</Grid>

						<Spacer />

						<Grid item xs={12}>
							{
								!isPreviousReport ? (
									<Box>

										{
											!loading ? (
												<Box
													sx={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<Button
														type="submit"
														color="primary"
														variant="contained"
														onClick={() => {
															setLoading(true);handleSave(false);
														}}
													>
														Definitief opslaan
													</Button>

													<Button
														type="submit"
														color="primary"
														variant="contained"
														onClick={() => {
															setLoading(true);handleSave(true);
														}}
													>
														Opslaan
													</Button>
												</Box>
											) : <CircularProgress />
										}

									</Box>
								) : (
									<Button
										color="primary"
										variant="contained"
										onClick={() => navigate(-1)}
									>
										Terug
									</Button>
								)
							}
						</Grid>
					</Grid>
				</form>
			</Box>

			{
				appointments.length ? (
					<Alert
						sx={{
							backgroundColor: "darkred", color:"white", mt: 2,
						}}
						severity="error"
						icon={<InfoIcon sx={{ color: "white" }} />}
						action={(
							<Button
								color="inherit"
								size="small"
								variant="outlined"
								onClick={() => setOpen(true)}
							>
								Bekijk agenda
							</Button>
						)}
					>
						Let op: er zijn geplande items
					</Alert>
				) : null
			}

			<Popup open={open} setOpen={setOpen}>
				<Box>
					{appointments ? (
						appointments.map((a, i) => (
							<Box key={a.id}>
								<Typography>
									{`Van: ${DateTime.fromMillis(a.start).toFormat("dd-MM-yyyy-HH:mm")}`}
								</Typography>

								<Typography>
									{`Tot: ${DateTime.fromMillis(a.end).toFormat("dd-MM-yyyy-HH:mm")}`}
								</Typography>

								<Typography>
									{`Beschrijving: ${a.title}`}
								</Typography>

								<Typography>
									{`Gemaakt door: ${a.createdBy}`}
								</Typography>

								<Typography>
									{`Locatie: ${a.location}`}
								</Typography>

								{i !== appointments.length - 1 ? (<Line />) : null}
							</Box>
						))
					): null}

					<Button sx={{ mt: 2 }} variant="contained" onClick={() => setOpen(false)}>Sluiten</Button>
				</Box>
			</Popup>

		</Box>
	);
};

const Line = () => (
	<Box sx={{
		height: 2,
		width: "100%",
		backgroundColor: "#000", my: 2,
	}}
	/>
);

export const Spacer = () => (
	<Grid
		item
		xs={12}
		sx={{
			display: "flex",
			justifyContent: "center",
		}}
	>
		<Box
			sx={{
				height: 2,
				width: "100%",
				backgroundColor: "#000",
				my: 3,
			}}
		/>
	</Grid>
);
