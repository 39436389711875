import React, { useState, useEffect } from "react";
import type { View } from "react-big-calendar";
import { Calendar, luxonLocalizer } from "react-big-calendar";
import {
	Box,
	Button,
	Fab,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import "./style.scss";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AddIcon from "@mui/icons-material/Add";
import { Popup } from "../../components";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import type {
	Appointment,
	CalendarAppointment,
	LocationType,
} from "../../types";
import { AppointmentRepository } from "../../data/firebase";
import { useAuth } from "../../hooks/useAuth";
import { enqueueSnackbar } from "notistack";
import { where } from "firebase/firestore";

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 });

export const Appointments = () => {
	const [ viewType, setViewType ] = useState<View>("day");
	const [ currentDate, setCurrentDate ] = useState<DateTime>(DateTime.now());
	const [ open, setOpen ] = useState<boolean>(false);
	const [ appointment, setAppointment ] = useState<CalendarAppointment | null>(null);

	const [ openAppointment, setOpenAppointment ] = useState<boolean>(false);
	const [ fromDate, setFromDate ] = useState<DateTime>(DateTime.now());
	const [ tillDate, setTillDate ] = useState<DateTime>(DateTime.now());
	const [ description, setDescription ] = useState<string>("");
	const [ editing, setEditing ] = useState<boolean>(false);
	const [ location, setLocation ] = useState<LocationType>("steenwijk");

	const [ locationFilter, setLocationFilter ] = useState<LocationType>("steenwijk");

	const [ appointments, setAppointments ] = useState<Appointment[]>();

	const { user } = useAuth();

	useEffect(() => {

		if (viewType === "work_week" || viewType === "agenda") return;

		let min, max;

		switch (viewType) {
			case "day":
				min = currentDate.startOf("day");
				max = currentDate.endOf("day");
				break;
			case "week":
				min = currentDate.startOf("week");
				max = currentDate.endOf("week");
				break;
			case "month":
				min = currentDate.startOf("month");
				max = currentDate.endOf("month");
				break;
			default:
				min = currentDate.startOf("day");
				max = currentDate.endOf("day");
				break;
		}

		AppointmentRepository
			.getCollectionsWithFilter([
				where("date", ">=", min.setLocale("nl-NL").toMillis()),
				where("date", "<=", max.setLocale("nl-NL").toMillis()),
			])
			.then((d) => {
				setAppointments(d.filter(v => v.location === locationFilter));
			})
			.catch((_) => {
				1;
			});

	}, [
		viewType, currentDate, locationFilter,
	]);
	const navigateDates = (direction: "back" | "forward") => {
		let newDate;
		switch (viewType) {
			case "day":
				newDate = direction === "back" ? currentDate.minus({ days: 1 }) : currentDate.plus({ days: 1 });
				break;
			case "week":
				newDate = direction === "back" ? currentDate.minus({ weeks: 1 }) : currentDate.plus({ weeks: 1 });
				break;
			case "month":
				newDate = direction === "back" ? currentDate.minus({ months: 1 }) : currentDate.plus({ months: 1 });
				break;
			default:
				newDate = direction === "back" ? currentDate.minus({ days: 1 }) : currentDate.plus({ days: 1 });
				break;
		}
		setCurrentDate(newDate);
	};

	const getDateString = () => {
		switch (viewType) {
			case "day":
				return currentDate.toFormat("dd MMMM");
			case "week":
				return `${currentDate.startOf("week").toFormat("dd")}/${currentDate.startOf("week").toFormat("MM")} - ${currentDate.endOf("week").toFormat("dd")}/${currentDate.endOf("week").toFormat("MM")}`;
			case "month":
				return currentDate.toFormat("MMMM");
			default:
				return currentDate.toFormat("dd MMMM");
		}
	};

	const filterData = (d: Appointment[]) => {
		setAppointments(d.filter(a => a.location === locationFilter));
	};

	const handleSaveAppointment = () => {
		const data = {
			id: "",
			start: fromDate.setLocale("nl-NL").toMillis(),
			end: tillDate.setLocale("nl-NL").toMillis(),
			title: description,
			createdBy: user?.username ?? "",
			date: fromDate.setLocale("nl-NL").toMillis(),
			location,
		};

		if (editing) {
			if (!appointment) return enqueueSnackbar("Er is iets misgegaan", { variant: "error" });
			AppointmentRepository.setDocument(appointment.id, data)
				.then(() => {
					enqueueSnackbar("Item aangepast", { variant: "success" });
					const toBeAdded = {
						...data,
						id: appointment.id,
					};
					setEditing(false);
					clearAppointment();
					filterData(appointments ? [ ...appointments.filter(a => a.id !== toBeAdded.id), toBeAdded ] : [toBeAdded]);
				})
				.catch(() => enqueueSnackbar("Er is iets misgegaan met het opslaan", { variant: "error" }));
		} else {
			AppointmentRepository.addDocument(data)
				.then(() => {
					enqueueSnackbar("Afspraak aangemaakt", { variant: "success" });
					clearAppointment();
					filterData(appointments ? [ ...appointments, data ] : [data]);
				})
				.catch(() => {
					enqueueSnackbar("Kon de afspraak niet aanmaken", { variant: "error" });
					clearAppointment();
				});
		}

	};

	const clearAppointment = () => {
		setFromDate(DateTime.now());
		setTillDate(DateTime.now());
		setDescription("");
	};

	const handleDelete = () => {
		if (!appointment?.id) return enqueueSnackbar("Kon het niet verwijderen", { variant: "error" });

		AppointmentRepository.deleteDocument(appointment.id)
			.then(() => {
				enqueueSnackbar("Item verwijderd", { variant: "success" });
				setAppointments(appointments?.filter(a => a.id !== appointment.id));
				setAppointment(null);
			})
			.catch(() => enqueueSnackbar("Kon het niet verwijderen", { variant: "error" }));
	};

	const handleEdit = () => {
		const from = DateTime.fromJSDate(appointment?.start ?? new Date());
		const end = DateTime.fromJSDate(appointment?.end ?? new Date());

		setEditing(true);
		setFromDate(from);
		setTillDate(end);
		setDescription(appointment?.title ?? "");

		setOpenAppointment(true);

	};

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<FormControl>
					<InputLabel id="locatieId">Locaties</InputLabel>

					<Select
						labelId="locatieId"
						id="demo-simple-select"
						value={locationFilter}
						label="Locaties"
						onChange={e => setLocationFilter(e.target.value as LocationType)}
					>
						<MenuItem value="steenwijk">Steenwijk</MenuItem>

						<MenuItem value="oldemarkt">Oldemarkt</MenuItem>

						<MenuItem value="giethoorn">Giethoorn</MenuItem>
					</Select>
				</FormControl>

				<Box sx={{ display: { xs: "none", sm: "block" } }}>
					<Button onClick={() => setOpenAppointment(true)} variant="contained">
						Nieuwe afspraak
					</Button>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					px: 2,
					mt: 3,
				}}
			>
				<Box>
					<IconButton onClick={() => navigateDates("back")} sx={{ color: "#000" }}>
						<ArrowBackIosIcon />
					</IconButton>

					<IconButton onClick={() => setCurrentDate(DateTime.now())} sx={{ color: "#000" }}>
						<DateRangeIcon />
					</IconButton>

					<IconButton onClick={() => navigateDates("forward")} sx={{ color: "#000" }}>
						<ArrowForwardIosIcon />
					</IconButton>
				</Box>

				<Box>
					{getDateString()}
				</Box>

				<Box sx={{ minWidth: "100px" }}>
					<FormControl>
						<InputLabel id="viewTypeId">Type</InputLabel>

						<Select
							labelId="viewTypeId"
							id="demo-simple-select"
							value={viewType}
							label="Type"
							onChange={e => setViewType(e.target.value as View)}
						>
							<MenuItem value="day">Dag</MenuItem>

							<MenuItem value="week">Week</MenuItem>

							<MenuItem value="month">Maand</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>

			<Calendar
				localizer={localizer}
				events={appointments ? appointments.map(a => ({
					...a,
					start: DateTime.fromMillis(a.start).toJSDate(),
					end: DateTime.fromMillis(a.end).toJSDate(),
				})) : []}
				view={viewType}
				onView={v => setViewType(v)}
				date={currentDate.toJSDate()}
				onNavigate={(newDate) => {
					const luxonDate = DateTime.fromJSDate(newDate);
					setCurrentDate(luxonDate);
				}}
				style={{ height: 500 }}
				onSelectEvent={(e) => {
					setOpen(true); setAppointment(e);
				}}
				enableAutoScroll
				scrollToTime={DateTime.now().toJSDate()}
				startAccessor="start"
				formats={{ eventTimeRangeFormat: ({ start, end }) => `${DateTime.fromJSDate(start).toFormat("HH:mm")}-${DateTime.fromJSDate(end).toFormat("HH:mm")}` }}
			/>

			<Popup open={open} setOpen={setOpen}>
				<Box>
					{
						appointment ? (
							<Box>

								<Typography>
									{`Van: ${DateTime.fromJSDate(appointment.start).toFormat("dd-MM-yyyy-HH:mm")}`}
								</Typography>

								<Typography>
									{`Tot: ${DateTime.fromJSDate(appointment.end).toFormat("dd-MM-yyyy-HH:mm")}`}
								</Typography>

								<Typography>
									{`Beschrijving: ${appointment.title}`}
								</Typography>

								<Typography>
									{`Gemaakt door: ${appointment.createdBy}`}
								</Typography>

								<Typography>
									{`Locatie: ${appointment.location}`}
								</Typography>

								<Box
									sx={{
										display: "flex", justifyContent: "space-between", mt:3,
									}}
								>

									<Button
										onClick={() => {
											setOpen(false);
										}}
										variant="contained"
									>
										Sluit
									</Button>

									<Button
										variant="contained"
										color="warning"
										onClick={() => {
											setOpen(false);
											handleEdit();
										}}
									>
										Aanpassen
									</Button>

									<Button
										variant="contained"
										color="error"
										onClick={() => {
											setOpen(false);
											handleDelete();
										}}
									>
										Verwijderen
									</Button>
								</Box>
							</Box>
						) : null
					}
				</Box>
			</Popup>

			<Popup open={openAppointment} setOpen={setOpenAppointment}>

				<Box>
					<MobileDateTimePicker
						label="Datum vanaf"
						value={fromDate}
						onChange={v => v ? setFromDate(v) : null}
						sx={{ width: "100%" }}
					/>

					<MobileDateTimePicker
						label="Datum tot"
						value={tillDate}
						onChange={v => v ? setTillDate(v) : null}
						sx={{ mt: 3, width: "100%"  }}
					/>

					<TextField
						value={description}
						onChange={e => setDescription(e.target.value)}
						label="Beschrijving"
						variant="outlined"
						fullWidth
						sx={{ mt: 3 }}
					/>

					<FormControl sx={{ mt: 3 }}>
						<InputLabel id="locatieSelectId">Locaties</InputLabel>

						<Select
							labelId="locatieSelectId"
							id="demo-simple-select"
							value={location}
							label="Locaties"
							onChange={e => setLocation(e.target.value as LocationType)}
						>
							<MenuItem value="steenwijk">Steenwijk</MenuItem>

							<MenuItem value="oldemarkt">Oldemarkt</MenuItem>

							<MenuItem value="giethoorn">Giethoorn</MenuItem>
						</Select>
					</FormControl>

					<Box
						sx={{
							display: "flex", justifyContent: "space-between", mt:3,
						}}
					>
						<Button
							onClick={() => {
								setOpenAppointment(false);
								handleSaveAppointment();
							}}
							variant="contained"
							color="success"
						>
							Opslaan
						</Button>

						<Button
							onClick={() => {
								setOpenAppointment(false);
							}}
							variant="contained"
						>
							Sluit
						</Button>
					</Box>
				</Box>

			</Popup>

			<Fab
				color="primary"
				aria-label="add"
				sx={{
					display: { xs: "flex", sm: "none" },
					position: "fixed",
					bottom: 8,
					right: 8,
				}}
				onClick={() => setOpenAppointment(true)}
			>
				<AddIcon />
			</Fab>

		</Box>
	);
};
