import type {
	CollectionReference,
	QueryConstraint,
	WithFieldValue,
} from "firebase/firestore";
import {
	addDoc,
	deleteDoc,
	doc,
	getDocFromServer,
	getDocs,
	query,
	setDoc,
} from "firebase/firestore";
import type {
	Appointment,
	Location,
	Report,
	User,
} from "../types";

class Actions<T> {
	private ref: CollectionReference<T>;

	constructor(ref: CollectionReference<T>){
		this.ref = ref;
	}

	/**
	 * Gets all documents in a collection
	 */
	getCollections = async (): Promise<T[]> => {
		const snapshot = await getDocs(this.ref);
		return new Promise((resolve, reject) => {
			!snapshot.empty
				? resolve(snapshot.docs.map(d => d.data()) )
				: reject(new Error("Couldn't get collections"));
		});
	};

	/**
	 * Filter conditions in a clause are specified using the
	 * strings '&lt;', '&lt;=', '==', '!=', '&gt;=', '&gt;', 'array-contains', 'in',
	 * 'array-contains-any', and 'not-in'.
	 * @param `contraints` - constraints.
	 */
	getCollectionsWithFilter = async (
		constraints: QueryConstraint[],
	): Promise<T[]> => {
		const snapshot = await getDocs(query(this.ref, ...constraints));
		return new Promise((resolve, reject) => {
			!snapshot.empty
				? resolve(snapshot.docs.map(d => d.data()) )
				: reject(new Error("Couldn't get collections"));
		});
	};

	/**
	 * Gets single document from collection
	 * @param `id` - the id to a document.
	 */
	getDocument = async (
		id: string,
	): Promise<T> => {
		const snapshot = await getDocFromServer(doc(this.ref, id));
		return new Promise((resolve, reject) => {
			snapshot.exists()
				? resolve(snapshot.data())
				: reject(new Error("Could not find document"));
		});
	};

	/**
	 * Adds data to specific collection
	 * @param `data` - data to be added.
	 */
	addDocument = async (
		data: WithFieldValue<T>,
	) => addDoc(this.ref, data);

	/**
	 * Updates data of specific collection.
	 * Creates a new document if given document by id does not exist
	 * @param `id` - id of a document.
	 * @param `data` - data to be added.
	 */
	setDocument = async (
		id: string,
		data: WithFieldValue<T>,
	) => setDoc(doc(this.ref, id), data);

	/**
	 * Deletes a specific document.
	 * @param `id` - id of a document.
	 */
	deleteDocument = async (
		id: string,
	) => deleteDoc(doc(this.ref, id));

}

export class ReportCollection extends Actions<Report> {
	constructor(ref: CollectionReference<Report>) {
		super(ref);
	}
}

export class UserCollection extends Actions<User>{
	constructor(ref: CollectionReference<User>){
		super(ref);
	}
}

export class LocationCollection extends Actions<Location>{
	constructor(ref: CollectionReference<Location>){
		super(ref);
	}
}

export class AppointmentCollection extends Actions<Appointment>{
	constructor(ref: CollectionReference<Appointment>){
		super(ref);
	}
}
