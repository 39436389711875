import React from "react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import {
	Appointments,
	Home,
	Login,
	Reports,
} from "./pages";
import { Navigation, Layout } from "./components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./providers/AuthProvider";
import { PrivateRoutes } from "./components/private";

export const App = () => (
	<SnackbarProvider preventDuplicate maxSnack={3}>
		<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="nl">
			<BrowserRouter>
				<AuthProvider>
					<Navigation />

					<Layout>
						<Routes>

							<Route element={<PrivateRoutes />}>

								<Route path="/" element={<Home />} />

								<Route path="/report" element={<Reports />} />

								<Route path="/report/:id" element={<Reports />} />

								<Route path="/agenda" element={<Appointments />} />

							</Route>

							<Route path="login" element={<Login />} />
						</Routes>
					</Layout>

				</AuthProvider>
			</BrowserRouter>
		</LocalizationProvider>
	</SnackbarProvider>
);
