import React, { useState, useEffect } from "react";
import type {
	ReportTable,
	Report,
	ShiftType,
	ReportForMonth,
} from "../../types";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { DataGrid, type GridColDef } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { ReportRepository } from "../../data/firebase";
import { enqueueSnackbar } from "notistack";
import { DateTime } from "luxon";
import { FormatShiftTypeToString } from "../../util";
import "./style.css";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { where } from "firebase/firestore";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Popup } from "../../components";
import { MonthlyReport } from "../../components/monthlyReport";
import { useAuth } from "../../hooks/useAuth";

type DateValueFormatter = {
	value: number
}

export const Home = () => {
	// Table reports
	const [ reports, setReports ] = useState<Report[]>([]);
	const [ fromDate, setFromDate ] = useState<DateTime>(DateTime.now().minus({ week: 1 }));
	const [ tillDate, setTillDate ] = useState<DateTime>(DateTime.now());

	// Select month for monthly reports
	const [ fromDateReportMonth, setFromDateReportMonth ] = useState<DateTime>(DateTime.now().minus({ weeks: 1 }));
	const [ tillDateReportMonth, setTillDateReportMonth ] = useState<DateTime>(DateTime.now());
	const [	reportsForMonth, setReportsForMonth ] = useState<ReportForMonth[]>();
	const [	cleanedReportsForMonth, setCleanedReportsForMonth ] = useState<ReportForMonth[]>();
	const [ generating, setGenerating ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ importantOnly, setImportantOnly ] = useState<boolean>(false);
	const navigate = useNavigate();
	const { user } = useAuth();

	useEffect(() => {
		ReportRepository
			.getCollectionsWithFilter([
				where("date", ">=", fromDate.startOf("day").toMillis()),
				where("date", "<=", tillDate.endOf("day").toMillis()),
			])
			.then(d =>
				setReports(d),
			)
			.catch(_ => enqueueSnackbar("We konden geen gegevens vinden", { variant: "error" }));
	}, [ fromDate, tillDate ]);

	const columns: GridColDef[] = [
		{
			headerName: "Datum",
			field: "date",
			flex: 1,
			valueFormatter(params: DateValueFormatter) {

				return DateTime.fromMillis(params.value).setLocale("nl")
					.toLocaleString(DateTime.DATETIME_SHORT);
			},
		},
		{
			headerName: "Functie",
			field: "function",
			flex: 1,

		},
		{
			headerName: "Dienst type",
			field: "type",
			flex: 1,
			valueFormatter(params) {
				return FormatShiftTypeToString(params.value as ShiftType);
			},
		},
		{
			headerName: "Huismeester",
			field: "caretaker",
			flex: 1,
		},
		{
			headerName: "Locatie",
			field: "location",
			flex: 1,
		},
		{
			headerName: "",
			field: "",
			renderCell(params) {
				const row: ReportTable = params.row as ReportTable;
				return (
					<Button
						variant="contained"
					>
						<Link
							style={{ textDecoration: "none", color: "#fff" }}
							to={`/report/${row.id}`}
							target="_blank"
							type="button"
						>
							Ga
						</Link>

					</Button>
				);
			},
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
	];

	const handleSearchReports = () => {
		const fromDateMillis = fromDateReportMonth
			.setLocale("nl-NL")
			.toMillis();

		const tillDateMillis = tillDateReportMonth
			.setLocale("nl-NL")
			.toMillis();

		ReportRepository
			.getCollectionsWithFilter([
				where("hasImportantComment", "==", true),
				where("date", ">=", fromDateMillis),
				where("date", "<=", tillDateMillis),
			])
			.then((d) => {
				// Map the data which have important comments

				const output = d.flatMap(i =>
					i.comments.filter(c => c.important).map(c => ({
						fromDate: c.fromDate,
						tillDate: c.tillDate,
						comment: c.comment,
						images: c.images ?? null,
						caretaker: i.caretaker,
						function: i.function,
						date: i.date,
						location: i.location,
						commentLocation: c.location ?? "",
					})),
				).sort((a, b) => a.date > b.date ? 1 : -1);

				setReportsForMonth(output);
			})
			.catch(_ => enqueueSnackbar("Kon geen rapportages vinden voor deze maand", { variant: "error" }));

	};

	const handleRemoveComment = (c: ReportForMonth) => {
		setReportsForMonth(prev => prev?.filter(p => p !== c));
	};

	return (
		<div>
			{
				!generating ? (
					<Box
						px={{ xs: "5px", md: "10%" }}
						component="div"
						className="print"
					>
						<Box
							p={{ xs: 0, md: 3 }}
							sx={{ backgroundColor: "#f4f4f4", borderRadius: 2 }}
						>
							<Box mb={3} mt={{ xs: 3, md:0 }}>
								{
									reports.filter(r => r.caretaker === user?.username && r.status === "concept").map(r => (
										<Alert
											severity="info"
											variant="outlined"
											key={r.id}
											action={(
												<Button color="info" onClick={() => navigate(`/report/${r.id}`)}>
													Bekijk
												</Button>
											)}
										>
											Je hebt nog een openstaande dienstrapportage
										</Alert>
									))
								}
							</Box>

							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Typography variant="h5">Dienstrapportages</Typography>

								<Button
									variant="contained"
									color="success"
									endIcon={<PrintIcon />}
									onClick={() => setOpen(true)}
									sx={{ display: { xs: "none", sm: "flex" } }}
								>
									Weekelijkse rapportage
								</Button>
							</Box>

							<Box mt={3}>
								<Grid container spacing={3}>
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<MobileDatePicker
											label="Datum vanaf"
											value={fromDate}
											onChange={newValue => newValue ? setFromDate(newValue) : null}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={3}
										sx={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<MobileDatePicker
											label="Datum tot"
											value={tillDate}
											onChange={newValue => newValue ? setTillDate(newValue) : null}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={6}
									>
										<FormGroup>
											<FormControlLabel
												control={(
													<Checkbox
														value={importantOnly}
														onChange={() => setImportantOnly(!importantOnly)}
													/>
												)}
												label="Toon alleen diensten met belangrijke bijzonderheden"
											/>
										</FormGroup>
									</Grid>
								</Grid>

							</Box>

							<Box mt={3}>
								<DataGrid
									className="test"
									density="comfortable"
									disableRowSelectionOnClick
									disableColumnSelector
									onCellClick={(p, e) => 1 && e.stopPropagation()}
									getRowId={(row: Report) => row.id}
									columns={columns}
									rows={importantOnly ? reports.filter(r => r.comments.find(c => c.important)) : reports.filter(r => r.status === "final")}
								/>
							</Box>

							<Box>
								<Popup
									open={open}
									setOpen={setOpen}
								>
									<Box>
										<Typography variant="h5">Overzicht rapportage</Typography>

										<Box
											mt={3}
											sx={{
												display: "flex",
												alignItems: "center",
											}}
										>
											{/* hier */}
											<MobileDatePicker
												views={[ "month", "day" ]}
												label="Datum vanaf"
												value={fromDateReportMonth}
												onChange={newValue => newValue ? setFromDateReportMonth(newValue) : null}
												sx={{ mr: 3 }}
											/>

											<MobileDatePicker
												views={[ "month", "day" ]}
												label="Datum tot"
												value={tillDateReportMonth}
												onChange={newValue => newValue ? setTillDateReportMonth(newValue) : null}
												sx={{ mr: 3 }}
											/>

											<Button
												variant="contained"
												color="success"
												endIcon={<SearchIcon />}
												onClick={handleSearchReports}
											>
												Zoeken
											</Button>
										</Box>

										<Box
											mt={3}
											sx={{ maxHeight: 500, overflowY:"scroll" }}
										>
											<TableContainer component={Paper}>
												<Table sx={{ minWidth: 800 }} aria-label="simple table">
													<TableHead>
														<TableRow>
															<TableCell width={70}>Datum</TableCell>

															<TableCell>Naam</TableCell>

															<TableCell>Van</TableCell>

															<TableCell>Tot</TableCell>

															<TableCell>Plaats</TableCell>

															<TableCell>Locatie</TableCell>

															<TableCell>Bijzonderheden</TableCell>

															<TableCell />
														</TableRow>
													</TableHead>

													<TableBody
														sx={{ overflowY: "scroll" }}
													>

														{
															!!reportsForMonth && reportsForMonth.map((r, i) => (
																<TableRow
																	key={i}
																	sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
																>
																	<TableCell component="th" scope="row">
																		{
																			DateTime.fromMillis(r.date)
																				.setLocale("nl-NL")
																				.toLocaleString(DateTime.DATE_SHORT)

																		}
																	</TableCell>

																	<TableCell>
																		{r.caretaker}
																	</TableCell>

																	<TableCell>
																		{
																			DateTime.fromMillis(r.fromDate)
																				.setLocale("nl-NL")
																				.toLocaleString(DateTime.TIME_SIMPLE)
																		}
																	</TableCell>

																	<TableCell>
																		{
																			r.tillDate ? (
																				DateTime.fromMillis(r.tillDate)
																					.setLocale("nl-NL")
																					.toLocaleString(DateTime.TIME_SIMPLE)
																			) : ""
																		}
																	</TableCell>

																	<TableCell>
																		{r.location}
																	</TableCell>

																	<TableCell>
																		{r.commentLocation}
																	</TableCell>

																	<TableCell>
																		{r.comment}
																	</TableCell>

																	<TableCell>
																		<IconButton color="error" onClick={() => handleRemoveComment(r)}>
																			<RemoveCircleIcon />
																		</IconButton>
																	</TableCell>
																</TableRow>

															))
														}
													</TableBody>
												</Table>
											</TableContainer>

										</Box>

										<Button
											variant="contained"
											color="success"
											onClick={() => {
												setCleanedReportsForMonth(reportsForMonth); setGenerating(true);
											}}
											sx={{ mt: 3 }}
										>
											Preview
										</Button>
									</Box>

								</Popup>
							</Box>
						</Box>
					</Box>
				) : (
					<MonthlyReport reports={cleanedReportsForMonth} setGenerating={setGenerating} />
				)
			}

		</div>
	);
};
