import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import type { ReportForMonth, UploadedImage } from "../../types";
import "./style.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PrintIcon from "@mui/icons-material/Print";
import Logo from "../../assets/dropshadow.png";
import { DateTime } from "luxon";

type Props = {
	reports: ReportForMonth[] | undefined
	setGenerating: React.Dispatch<React.SetStateAction<boolean>>
}

export const MonthlyReport = ({ reports, setGenerating }: Props) => {
	const [ imageList, setImageList ] = useState<UploadedImage[]>([]);

	useEffect(() => {
		const images: UploadedImage[] = [];
		if (!reports) return;
		reports.forEach((report) => {
			if (report.images && report.images.length > 0) {
				report.images.forEach((image) => {
					images.push(image);
				});
			}
		});
		setImageList(images);
	}, [reports]);

	return (
		<Box>
			<Box
				px="10%"
				component="div"
				className="print"
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<IconButton onClick={() => setGenerating(false)} color="primary">
						<ArrowBackIosIcon />
					</IconButton>

					<Button
						variant="contained"
						color="success"
						endIcon={<PrintIcon />}
						onClick={() => {
							window.print();
						}}
					>
						Print
					</Button>
				</Box>
			</Box>

			{/* Actual print */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
				component="div"
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					<img src={Logo} alt="GL-Security Logo" style={{ height: "200px" }} />

					<Typography variant="h2">GL-Security</Typography>
				</Box>

				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
					}}
				>
					<Typography variant="h6">
						Datum:
						{" "}

						{DateTime.now().setLocale("nl")
							.toLocaleString(DateTime.DATE_SHORT)}
					</Typography>
				</Box>

				<PageBreak />

				<Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell width={70}>Datum</TableCell>

									<TableCell>Functie</TableCell>

									<TableCell>Naam</TableCell>

									<TableCell>Van</TableCell>

									<TableCell>Tot</TableCell>

									<TableCell>Plaats</TableCell>

									<TableCell>Locatie</TableCell>

									<TableCell>Bijzonderheden</TableCell>

									<TableCell>Bijlage</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{!!reports && reports.map((r, i) => (
									<TableRow
										key={i}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{DateTime.fromMillis(r.date).setLocale("nl-NL")
												.toLocaleString(DateTime.DATE_SHORT)}
										</TableCell>

										<TableCell>
											{r.function}
										</TableCell>

										<TableCell>
											{r.caretaker}
										</TableCell>

										<TableCell>
											{DateTime.fromMillis(r.fromDate).setLocale("nl-NL")
												.toLocaleString(DateTime.TIME_SIMPLE)}
										</TableCell>

										<TableCell>
											{r.tillDate ? DateTime.fromMillis(r.tillDate).setLocale("nl-NL")
												.toLocaleString(DateTime.TIME_SIMPLE) : ""}
										</TableCell>

										<TableCell>
											{r.location}
										</TableCell>

										<TableCell>
											{r.commentLocation}
										</TableCell>

										<TableCell>
											{r.comment}
										</TableCell>

										<TableCell>
											{
												r.images && r.images.length > 0
													? r.images.map((image) => {
														const imageListIndex = imageList.findIndex(x => x.name === image.name);
														return `[${imageListIndex + 1}] `;
													})
													: ""
											}

										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Box mt={2}>
						{imageList.map((image, index) => (
							<Box
								key={index}
								sx={{
									display: "inline-block", m: 1, border: "1px solid #ccc", padding: 1,
								}}
							>
								<img
									src={image.url}
									alt={image.name}
									style={{ maxWidth: 200, maxHeight: 200 }}
								/>

								<Box textAlign="center" sx={{ color: "blue" }}>
									<a href={image.url} target="_blank" rel="noreferrer">
										[
										{index + 1}
										]
									</a>
								</Box>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export const PageBreak = () => (<div className="break" />);
