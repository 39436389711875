import { DateTime } from "luxon";
import type { ShiftType } from "../types";

export const FormatShiftTypeToString = (t: ShiftType) => {
	switch (t) {
		case "dayWeekend":
			return "Dag weekend";

		case "nightWeekend":
			return "Nacht weekend";

		case "early":
			return "Vroege dienst";

		case "late":
			return "Late dienst";

		case "night":
			return "Nacht dienst";

		default:
			"-";
	}
};

export const MillisToDate = (m: number) =>
	DateTime.fromMillis(m).setLocale("nl")
		.toLocaleString(DateTime.DATETIME_SHORT);

export const MillisToTime = (m: number) =>
	DateTime.fromMillis(m).setLocale("nl")
		.toLocaleString(DateTime.TIME_SIMPLE);

// export const transform = (t: ReportForMonth[]) => t.flatMap(report => report.comments
// 	.filter((comment) => {
// 		const lowerCaseComment = comment.comment.toLowerCase();
// 		return (
// 			lowerCaseComment.includes("bmc")
// 				|| lowerCaseComment.includes("brandmeldcentrale")
// 				|| lowerCaseComment.includes("brand meld centrale")
// 		);
// 	})
// 	.map(comment => ({
// 		caretaker: report.caretaker,
// 		function: report.function,
// 		date: report.date,
// 		fromDate: comment.fromDate,
// 		tillDate: comment.tillDate,
// 		comment: comment.comment,
// 		location: report.location,
// 	})));
