import React, { useState } from "react";
import {
	AppBar,
	Container,
	Toolbar,
	Typography,
	Box,
	IconButton,
	Button,
	Tooltip,
	Menu,
	MenuItem,
	Fab,
} from "@mui/material";
import { LeftDrawer } from "../drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { getAuth, signOut } from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import "./style.css";
import Logo from "../../assets/dropshadow.png";

const settings = [
	"Profile", "Account", "Dashboard",
];

export const Navigation = () => {
	const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null);
	const [ open, setOpen ] = useState<boolean>(false);

	const { authenticated, user } = useAuth();
	const auth = getAuth();

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const navigate = useNavigate();

	const getInitials = (n: string) => {
		const names = n.split(" ");
		let initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	};

	return (
		<AppBar position="static" sx={{ backgroundColor: "#1f1f1f", display: authenticated ? "block": "none" }} className="print">
			<Container maxWidth="xl">
				<Toolbar disableGutters>

					<Box
						sx={{
							display: { xs: "none", md: "flex" },
							alignItems: "center",
							mr: 2,

						}}
					>
						<img src={Logo} height={25} />
					</Box>

					<Typography
						variant="h6"
						noWrap
						component="a"
						onClick={() => navigate("/")}
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontFamily: "monospace",
							fontWeight: 700,
							color: "inherit",
							textDecoration: "none",
						}}
					>
						GL Security
					</Typography>

					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={() => setOpen(true)}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>

						<LeftDrawer open={open} setOpen={setOpen} />
					</Box>

					<Typography
						variant="h5"
						noWrap
						component="a"
						href=""
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontFamily: "monospace",
							fontWeight: 700,
							color: "inherit",
							textDecoration: "none",
						}}
					>
						GL Security
					</Typography>

					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						<Button
							sx={{
								my: 2, color: "white", display: "block",
							}}
							onClick={() => navigate("/")}
						>
							Overzicht
						</Button>

						<Button
							sx={{
								my: 2, color: "white", display: "block",
							}}
							onClick={() => navigate("/report")}
						>
							Dienstrapportage
						</Button>

						<Button
							sx={{
								my: 2, color: "white", display: "block",
							}}
							onClick={() => navigate("/agenda")}
						>
							agenda
						</Button>

						<Button
							sx={{
								my: 2, color: "white", display: "block",
							}}
							onClick={() => window.open(
								"https://webmail.gl-security.nl",
								"_blank",
							)}
						>
							Webmail
						</Button>

					</Box>

					{/* Right side of nav */}
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<Fab
								sx={{
									backgroundColor: user ? user.color : "#fff", height: "45px", width: "45px",
								}}
								onClick={handleOpenUserMenu}
							>
								{
									user ? (
										<Typography>
											{getInitials(user.username)}
										</Typography>
									) : ""
								}
							</Fab>
						</Tooltip>

						<Menu
							sx={{ mt: "45px" }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map(setting => (
								<MenuItem key={setting} onClick={handleCloseUserMenu}>
									<Typography textAlign="center">
										{setting}
									</Typography>
								</MenuItem>
							))}

							<MenuItem onClick={() => {
								handleCloseUserMenu();
								signOut(auth)
									.then(() => {
										enqueueSnackbar("Logged out", { variant: "success" });
									})
									.catch(_ => enqueueSnackbar("Error", { variant: "error" }));
							}}
							>
								<Typography textAlign="center">
									Logout
								</Typography>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
