import * as React from "react";
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";

type Props = {
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeftDrawer = ({ open, setOpen }: Props) => {
	const navigate = useNavigate();
	return (
		<div>
			<Drawer
				anchor="left"
				open={open}
				onClose={() => setOpen(false)}
			>
				<Box
					sx={{ width: 250 }}
					role="presentation"
				>
					<List>
						<ListItem disablePadding>
							<ListItemButton onClick={() => navigate("/")}>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>

								<ListItemText>
									Overzicht
								</ListItemText>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton onClick={() => navigate("/report")}>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>

								<ListItemText>
									Rapport
								</ListItemText>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton onClick={() => navigate("/agenda")}>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>

								<ListItemText>
									Agenda
								</ListItemText>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton onClick={() => window.location.href = "https://webmail.gl-security.nl/Mobile/Login.aspx"}>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>

								<ListItemText>
									Webmail
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>

					<Divider />
				</Box>
			</Drawer>
		</div>
	);
};
