import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
	getAuth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
} from "firebase/auth";
import { enqueueSnackbar } from "notistack";
import type { User } from "../types";
import { UserRepository } from "../data/firebase";

type Props = {
	children: JSX.Element[]
}

export const AuthProvider = ({ children }: Props) => {
	const [ authenticated, setAuthenticated ] = useState<boolean>(false);
	const [ loading, setLoading ] = useState<boolean>(true);
	const [ user, setUser ] = useState<User>();
	const navigate = useNavigate();
	const auth = getAuth();

	const getUser = (uid: string) => {
		UserRepository.getDocument(uid)
			.then((d) => {
				setUser(d);
			})
			.catch(_ => enqueueSnackbar("Kon de huidige gebruiker niet ophalen", { variant: "error" }));
	};

	const authenticateUser = (email: string, password: string): void => {
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				getUser(userCredential.user.uid);
				// console.log("success", userCredential);
				setAuthenticated(true);
				navigate("/");
			})
			.catch(_ => enqueueSnackbar("Probleem met inloggen, neem contact op", { variant: "error" }));
	};

	useEffect(() => {
		onAuthStateChanged(auth, (u) => {
			if (u) {
				getUser(u.uid);
				setAuthenticated(true);
				setLoading(false);
			} else {
				navigate("/login");
				setAuthenticated(false);
				setLoading(false);
			}
		});
	}, [ auth, navigate ]);

	const logout = () => {
		setAuthenticated(false);
	};

	return (
		<AuthContext.Provider value={{
			authenticated, authenticateUser, logout, user,
		}}
		>
			{!loading && children}
		</AuthContext.Provider>
	);
};
