/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { FirebaseApp, FirebaseOptions } from "firebase/app";
import { initializeApp } from "firebase/app";
import type {
	QueryDocumentSnapshot,
	DocumentData,
	Firestore,
} from "firebase/firestore";
import { collection, getFirestore } from "firebase/firestore";
import type { FirebaseStorage } from "firebase/storage";
import { getStorage } from "firebase/storage";
import {
	LocationCollection,
	AppointmentCollection,
	ReportCollection,
	UserCollection,
} from "./db";

const firebaseConfig: FirebaseOptions = {
	apiKey: "AIzaSyCT5Likn_YQN_r75ApxTwLj0Uewr716u0Q",
	authDomain: "glsecurity.firebaseapp.com",
	projectId: "glsecurity",
	storageBucket: "glsecurity.appspot.com",
	messagingSenderId: "45914608670",
	appId: "1:45914608670:web:c2c9fffc1f80148f56ca0f",
};

export const app: FirebaseApp = initializeApp(firebaseConfig);
export const firestore: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);

/*
	This helper function pipes your types through a firestore converter
	it will add the document id when a document has been requested
	or removes it when uploaded. Remove it if it is not desired.
**/
const converter = <T extends { id: string }>() => ({
	toFirestore: (d: T) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id, ...data } = d;
		return data;
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot) => {
		const data = snapshot.data();
		return {
			...data,
			id: snapshot.id,
		} as T;
	},
});

/**
 * This helper function will return a 'type safe' collection reference
 * @param path Path of the collection e.g. "users"
 * @param db The firestore instance
 * @returns A reference to the collection
 */
const collectionRef = <T extends DocumentData & { id: string }>(path: string, db: Firestore) =>
	collection(db, path).withConverter(converter<T>());

export const ReportRepository = new ReportCollection(collectionRef("reports", firestore));
export const UserRepository = new UserCollection(collectionRef("users", firestore));
export const LocationRepository = new LocationCollection(collectionRef("locations", firestore));
export const AppointmentRepository = new AppointmentCollection(collectionRef("appointments", firestore));
