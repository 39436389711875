import React, { useState } from "react";
import {
	Box,
	Button,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import "./style.css";
import { useAuth } from "../../hooks/useAuth";
import Logo from "../../assets/dropshadow.png";

export const Login = () => {
	const [ email, setEmail ] = useState<string>("");
	const [ password, setPassword ] = useState<string>("");

	const { authenticateUser } = useAuth();

	return (
		<Box
			sx={{ backgroundColor: "#1f1f1f", height: "100%" }}
		>
			<Grid
				container
				sx={{ backgroundColor: "#1f1f1f", height: "100%" }}
			>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						height: "100vh",
						backgroundColor: "#ffcf03",
						display: { xs: "none", md: "block" },
					}}
				>
					<Box
						px={3}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height:"100%",
						}}
					>
						<img src={Logo} alt="GL-Security Logo" style={{ width: "50%" }} />
					</Box>
				</Grid>

				<Grid
					item
					xs={12}
					md={6}
					sx={{
						height: "100vh",
						backgroundColor: "#1f1f1f",
					}}
				>
					<Box
						px={3}
						sx={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: { xs: "flex", md: "none" },
								justifyContent: "center",
							}}
						>
							<img src={Logo} alt="GL-Security Logo" style={{ width: "80%" }} />

						</Box>

						<Box>
							<Typography variant="h4" color="white" textAlign="center">Welkom op het GL-Security portaal</Typography>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								px: "15%",
							}}
						>
							<TextField
								label="E-mail"
								color="primary"
								focused
								sx={{ mt: 3 }}
								InputProps={{ style: { color: "#fff" } }}
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>

							<TextField
								label="Password"
								color="primary"
								focused
								type="password"
								InputProps={{ style: { color: "#fff" } }}
								sx={{ mt: 3 }}
								value={password}
								onChange={e => setPassword(e.target.value)}
							/>

							<Button
								variant="contained"
								color="success"
								sx={{
									mt: 3, backgroundColor: "#ffcf03 !important", color: "#000",
								}}
								onClick={() => authenticateUser(email, password)}
							>
								Login
							</Button>
						</Box>

					</Box>

				</Grid>
			</Grid>
		</Box>
	);
};
