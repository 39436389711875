import type { Dispatch } from "react";
import React from "react";
import { Box, Modal } from "@mui/material";

type Props = {
	open: boolean,
	setOpen: Dispatch<React.SetStateAction<boolean>>
	children: JSX.Element
}

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	boxShadow: 24,
	minWidth: { md: 500, xs: 300 },
	p: 4,
	borderRadius: 3,
};

export const Popup = ({
	open, setOpen, children,
}: Props) => (
	<Modal
		open={open}
		onClose={() => setOpen(false)}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
	>
		<Box sx={style}>
			{children}
		</Box>
	</Modal>
);
