import React from "react";
import { Box } from "@mui/material";
import type { ReactNode } from "react";
import { useAuth } from "../../hooks/useAuth";
import "./style.css";

interface Props {
	children: ReactNode
}

export const Layout = ({ children }: Props) => {
	const { authenticated } = useAuth();
	return (
		<Box>
			{
				authenticated ? (
					<Box
						sx={{ p: { xs: 0, md: 3 } }}
						component="div"
						className="lowerPadding"
					>
						{children}
					</Box>
				) : (
					<Box>
						{children}
					</Box>
				)
			}
		</Box>
	);
};
