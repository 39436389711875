import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const PrivateRoutes = () => {
	const { authenticated } = useAuth();

	return (
		authenticated ? <Outlet /> : <Navigate to="/login" />
	);
};
